import React, { useState } from 'react';
import './HarvestModal.css'; 

const HarvestModal = ({ title, image, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">{title}</h2>
            <button className="close-button" onClick={onClose}>
              &times;
            </button>

        </div>

        <div className="image-descriptions">
          <img src={image}/>
        </div>
      </div>
    </div>
  );
};

export default HarvestModal;
