import React, { useState } from "react";
import "./TastingModal.css";

const TastingModal = ({ title, image, imagesWithDescriptions, onClose, clase }) => {
  const [isOpen, setIsOpen] = useState(true);

  const openPdf = (pdf) => {
    const pdfUrl = `${pdf}#zoom=${150}`;
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className={`modal-content ${clase} `}>
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="image-descriptions">
          {imagesWithDescriptions.map((item, index) => (
            <div key={index} className="image-description">
              <h3>{item.titulo}</h3>
              <div className="boxImageDescription">
                {item.fichas.map((ficha, index2) => (
                  <div className="boxContImgTxt" key={index2}>
                    <div className="imageBox">
                      <img src={ficha.imagen} alt={`Image ${index2}`} />
                    </div>
                    <div className="boxTexto">
                      <p>{ficha.nombre}</p>
                      <button onClick={() => openPdf(ficha.datasheet)}>{ficha.boton}</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TastingModal;
