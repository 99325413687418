import React, { useState } from 'react';
import TasteCard from './util/TasteCard';
import BackImage from "../Assets/Sabores.png";
import Congelados from "../Assets/work-congelados.png";
import Esparragos from "../Assets/work-esparragos.png";
import SuperFood from "../Assets/work-superfoods.png";
import Especies from "../Assets/work-especies.png";
import Mermeladas from "../Assets/work-mermeladas.png";
import enText from '../config/en'
import esText from '../config/es'
import TastingModal from './util/TastingModal'
const Work = ({ onLanguageChange, idioma }) => {

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState();

  const openModal = (modal) => {
    setShowModal(true);
    setModal(modal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  let text = (idioma==='es'?esText:enText)

  return (
    <div className="work-section-container section">
      {showModal && (
        <TastingModal
          title={modal['nombre']}
          image="imagen-principal.jpg"
          imagesWithDescriptions={modal['jsonModal']}
          onClose={closeModal}
          clase="product"
        />
      )}

      <div className="work-background-image-container">

          <img className="work-background-image" src={BackImage}/>
          <div className="work-center-text">
            <h2>{text.flavors.titulo1} <br/> {text.flavors.titulo2}</h2>
            <p>{text.flavors.subtitulo}</p>
          </div>
          <div className="work-card-container-group">
            <div className="work-card-container">
              <TasteCard
                imageSrc={Esparragos}
                subtitle={text.flavors.tarjetas[0].nombre}
                button={text.flavors.boton}
                onCardButtonClick={() => openModal(text.flavors.tarjetas[0])}
              />
            </div>

            <div className="work-card-container">
              <TasteCard
                imageSrc={Congelados}
                subtitle={text.flavors.tarjetas[1].nombre}
                button={text.flavors.boton}
                onCardButtonClick={() => openModal(text.flavors.tarjetas[1])}
              />
            </div>

            <div className="work-card-container">
              <TasteCard
                imageSrc={Especies}
                subtitle={text.flavors.tarjetas[2].nombre}
                button={text.flavors.boton}
                onCardButtonClick={() => openModal(text.flavors.tarjetas[2])}
              />
            </div>

            <div className="work-card-container">
              <TasteCard
                imageSrc={Mermeladas}
                subtitle={text.flavors.tarjetas[3].nombre}
                button={text.flavors.boton}
                onCardButtonClick={() => openModal(text.flavors.tarjetas[3])}
              />
            </div>

            <div className="work-card-container">
              <TasteCard
                imageSrc={SuperFood}
                subtitle={text.flavors.tarjetas[4].nombre}
                button={text.flavors.boton}
                onCardButtonClick={() => openModal(text.flavors.tarjetas[4])}
              />
            </div>
          </div>
      </div>
    </div>
  );
};

export default Work;
