import React, { useState } from "react";
import Logo from "../Assets/Logo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getImagePath } from "../config/config";

const Navbar = ({ onLanguageChange, idioma,  sections, scrollToSection }) => {

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar-links-container">
        {sections.map((section, index) => (
            <a key={index} href="#" onClick={() => scrollToSection(index)}>
              {section.text}
            </a>
        ))}

        <a onClick={onLanguageChange} style={{ cursor: 'pointer' }}>
          <img src={getImagePath(idioma)} alt="" style={{ width: '100px' }}/>
        </a>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {sections.map((item,index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton  onClick={() => scrollToSection(index)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
              <a onClick={onLanguageChange} style={{ cursor: 'pointer', padding: '4rem' }}>
                  <img src={getImagePath(idioma)} alt="" style={{ width: '100px' }}/>
              </a>
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
