import React from "react";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import enText from '../config/en'
import esText from '../config/es'

const Home = ({ onLanguageChange, idioma, sections, scrollToSection  }) => {
  let text = (idioma==='es'?esText:enText)

  return (
    <div className="home-container">
      <Navbar onLanguageChange={onLanguageChange} idioma={idioma}  sections={sections} scrollToSection={scrollToSection}/>
      <div className="home-banner-container">

        <div className="home-text-section">
          <h1 className="primary-heading">
            {text.home.titulo}
          </h1>
          <p className="primary-text">
            {text.home.subtitulo}
          </p>
          <button className="secondary-button" onClick={() => scrollToSection(3)}>
            {text.home.boton} <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
