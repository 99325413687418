import React, { useRef, useState, useEffect } from "react";
import MapMarker from "../Assets/map-marker.svg";
import Vector from "../Assets/Vector.svg";
import WSP from "../Assets/32365-ai.png";
import swal from "sweetalert";
import enText from '../config/en'
import esText from '../config/es'
import fruitsLeft from "../Assets/fruits-left-edit.png";
import fruitsRight from "../Assets/fruits-right.png";

// import emailjs from 'emailjs-com';
import emailjs from "@emailjs/browser";

const Contact = ({ onLanguageChange, idioma }) => {
  let text = idioma === "es" ? esText : enText;
  const [codPais, setCodPais] = useState("+51")
  // text === "es" ? setCodPais("+51") : setCodPais("+1")
  const [inputValue, setInputValue] = useState(""); // Estado para almacenar el valor del input
  

  const nombreRef = useRef();
  const companiaRef = useRef();
  const emailRef = useRef();
  const codigoPaisRef = useRef();
  const numeroTelefonoRef = useRef();
  const mensajeRef = useRef();
  // codigoPaisRef.current.value = text.contacto.placeholder[3];

  const updateInputValue = () => {
    const newValue = idioma === "es" ? "+51" : "+1";
    setInputValue(newValue);
  };

  // Llamamos a updateInputValue cuando cambia el idioma
  useEffect(() => {
    updateInputValue();
  }, [idioma]);


  const mostrarAlerta = () => {
    swal({
      title: text.contacto.swalMessage,
      icon: "success",
      button: text.contacto.swalButton,
    });
  };

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_tb6794w",
        "template_dvvc90b",
        event.target,
        "YVqHryTduly8q6xGG"
      )


      .then((response) => console.log(response))
      .catch((error) => console.log(error));

    // Después de enviar el mensaje, limpiar los campos
    nombreRef.current.value = "";
    companiaRef.current.value = "";
    emailRef.current.value = "";
    codigoPaisRef.current.value = text.contacto.placeholder[3];
    numeroTelefonoRef.current.value = "";
    mensajeRef.current.value = "";

    mostrarAlerta();
  };

  return (
    <div  className="section">
      <div className="contact-page-wrapper">
        <img className="fruits f_left" src={fruitsLeft} alt="Fruits Left" />
        <img className="fruits f_right" src={fruitsRight} alt="Fruits Right" />
        <h1 className="primary-heading">{text.contacto.titulo1}</h1>
        <h1 className="primary-heading">{text.contacto.titulo2}</h1>
        <form className="contact-form-container" onSubmit={sendEmail}>
          <div className="contact-box-input">
            <div className="contact-inputs">
              <input
                name="nombre"
                type="text"
                placeholder={text.contacto.placeholder[0]}
                ref={nombreRef}
                required
                maxLength="50"
              />
              <input
                name="compania"
                type="text"
                placeholder={text.contacto.placeholder[1]}
                ref={companiaRef}
                required
                maxLength="50"
              />
              <input
                name="email"
                type="text"
                placeholder={text.contacto.placeholder[2]}
                ref={emailRef}
                required
                maxLength="50"
              />
              <div className="tef">
                <input
                  name="cod-pais"
                  type="text"
                  placeholder={text.contacto.placeholder[3]}
                  // defaultValue={text.contacto.placeholder[3]}
                  defaultValue={inputValue}
                  className="ppp"
                  ref={codigoPaisRef}
                  required
                  maxLength="15"
                />
                <input
                  className="telefon"
                  name="telefono"
                  type="text"
                  placeholder={text.contacto.placeholder[4]}
                  ref={numeroTelefonoRef}
                  maxLength="20"
                />
              </div>
            </div>
            <textarea
              name="mensaje"
              id=""
              cols="30"
              rows="6"
              placeholder={text.contacto.placeholder[5]}
              ref={mensajeRef}
              maxLength="200"
            ></textarea>
          </div>
          <button className="secondary-button">{text.contacto.boton}</button>
        </form>
      </div>
      <div className="contact-page-dir">
        <div className="first">
          <img src={MapMarker} alt="MapMarker" />
          <p>
            Calle 54 N° 109; Of 204; Urb. Córpac<br></br>San Isidro - Lima -
            Perú
          </p>
        </div>
        <div>
          <img src={Vector} alt="Vector" />
          <p>corvale8@gmail.com</p>
        </div>
        <div>
          <img src={WSP} alt="WSP" />
          <p>+51 963 464 450</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
