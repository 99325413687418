import React from "react";
import Logo from "../Assets/Logo.png";

import enText from '../config/en'
import esText from '../config/es'

const Footer = ({ onLanguageChange, idioma , sections, scrollToSection}) => {
  let text = (idioma==='es'?esText:enText)
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          © 2023 Corporación Valerio SAC
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span onClick={() => scrollToSection(0)}>{text.footer.datos[0]}</span>
          <span onClick={() => scrollToSection(1)}>{text.footer.datos[1]}</span>
          <span onClick={() => scrollToSection(2)}>{text.footer.datos[2]}</span>
          <span onClick={() => scrollToSection(3)}>{text.footer.datos[3]}</span>
        </div>
        <div className="footer-section-columns">
          <span>+51 963 464 450</span>
          <span>corvale8@gmail.com</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
