import React from "react";
import Card from './util/Card';
import Commitment from '../Assets/commitment.png'
import Compliance from '../Assets/compliance.png'
import Quality from '../Assets/quality.png'
import BackImage from '../Assets/backx.png'
import enText from '../config/en'
import esText from '../config/es'

const About = ({ onLanguageChange, idioma }) => {

  let text = (idioma==='es'?esText:enText)
  let newClassName = (idioma==='es'?'about-center-text about-transform-1':'about-center-text about-transform-2')
    
  return (
    <div className="about-section-container section" >

      <div className="about-background-image-container">
          <img className="about-background-image" src={BackImage}/>
          <div className={newClassName}>
            <h2> {text.about.titulo}</h2>
          </div>
      </div>

      <div className="about-background-black-image-container">
          <h3>{text.about.subtitulo1} <br/>  {text.about.subtitulo2}</h3>
      </div>
      <div className="about-card-container-group">
        <div className="about-card-container">
          <Card
            imageSrc={Compliance}
            subtitle={text.about.tarjetas[0].titulo}
            text={text.about.tarjetas[0].subtitulo}
          />
        </div>
        <div className="about-card-container">
          <Card
            imageSrc={Commitment}
            subtitle={text.about.tarjetas[1].titulo}
            text={text.about.tarjetas[1].subtitulo}
          />
        </div>
        <div className="about-card-container">
          <Card
            imageSrc={Quality}
            subtitle={text.about.tarjetas[2].titulo}
            text={text.about.tarjetas[2].subtitulo}
          />
        </div>
      </div>


    </div>
  );
};

export default About;
