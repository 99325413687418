//Fichas técnicas
import ds_esparragos from "../Assets/fichas-tecnicas/1-conserva/1-esparragos-en.pdf";
import ds_gandules from "../Assets/fichas-tecnicas/1-conserva/2-gandules-en.pdf"
import ds_puerro from "../Assets/fichas-tecnicas/1-conserva/3-puerro-en.pdf"

import ds_maracuya from "../Assets/fichas-tecnicas/2-congelado/1-maracuya-en.pdf"
import ds_mango from "../Assets/fichas-tecnicas/2-congelado/2-mango-en.pdf"
import ds_limon from "../Assets/fichas-tecnicas/2-congelado/3-limon-en.pdf"
import ds_ajos from "../Assets/fichas-tecnicas/2-congelado/4-ajos-en.pdf"
import ds_camote from "../Assets/fichas-tecnicas/2-congelado/5-camote-en.pdf"
import ds_habas from "../Assets/fichas-tecnicas/2-congelado/6-habas-en.pdf"
import ds_maiz from "../Assets/fichas-tecnicas/2-congelado/7-maiz-en.pdf"
import ds_olluco from "../Assets/fichas-tecnicas/2-congelado/8-olluco-en.pdf"
import ds_vainita from "../Assets/fichas-tecnicas/2-congelado/9-vainita-en.pdf"
import ds_yuca from "../Assets/fichas-tecnicas/2-congelado/10-yuca-en.pdf"
import ds_zapallo from "../Assets/fichas-tecnicas/2-congelado/11-zapallo-en.pdf"
import ds_zanahoria from "../Assets/fichas-tecnicas/2-congelado/12-zanahoria-en.pdf"

import ds_curcuma from "../Assets/fichas-tecnicas/3-especies/1-curcuma-en.pdf"
import ds_achiote from "../Assets/fichas-tecnicas/3-especies/2-achiote-en.pdf"
import ds_gengibre from "../Assets/fichas-tecnicas/3-especies/3-gengibre-en.pdf"
import ds_hongos from "../Assets/fichas-tecnicas/3-especies/4-hongos-en.pdf"
import ds_paprika from "../Assets/fichas-tecnicas/3-especies/5-paprika-en.pdf"
import ds_oregano from "../Assets/fichas-tecnicas/3-especies/6-oregano-en.pdf"
import ds_tomillo from "../Assets/fichas-tecnicas/3-especies/7-tomillo-en.pdf"

import ds_tradicional from "../Assets/fichas-tecnicas/4-mermelada/1-tradicional-en.pdf"
import ds_extralight from "../Assets/fichas-tecnicas/4-mermelada/2-extralight-en.pdf"
import ds_dietlight from "../Assets/fichas-tecnicas/4-mermelada/3-dietlight-en.pdf"

import ds_lucuma from "../Assets/fichas-tecnicas/5-superfood/1-lucuma-en.pdf"
import ds_kiwicha from "../Assets/fichas-tecnicas/5-superfood/2-kiwicha-en.pdf"
import ds_maizmorado from "../Assets/fichas-tecnicas/5-superfood/3-maizmorado-en.pdf"
import ds_quinuas from "../Assets/fichas-tecnicas/5-superfood/4-quinuas-en.pdf"
import ds_chia from "../Assets/fichas-tecnicas/5-superfood/5-chia-en.pdf"

//Productos
import p_esparragos from "../Assets/productos/1-conserva/1-esparragos.webp"
import p_gandules from "../Assets/productos/1-conserva/2-gandules.webp"
import p_puerro from "../Assets/productos/1-conserva/3-puerro.webp"

import p_maracuya from "../Assets/productos/2-congelado/1-maracuya.webp"
import p_mango from "../Assets/productos/2-congelado/2-mango.webp"
import p_limon from "../Assets/productos/2-congelado/3-limon.webp"
import p_ajos from "../Assets/productos/2-congelado/4-ajos.webp"
import p_camote from "../Assets/productos/2-congelado/5-camote.webp"
import p_habas from "../Assets/productos/2-congelado/6-habas.webp"
import p_maiz from "../Assets/productos/2-congelado/7-maiz.webp"
import p_olluco from "../Assets/productos/2-congelado/8-olluco.webp"
import p_vainita from "../Assets/productos/2-congelado/9-vainita.webp"
import p_yuca from "../Assets/productos/2-congelado/10-yuca.webp"
import p_zapallo from "../Assets/productos/2-congelado/11-zapallo.webp"
import p_zanahoria from "../Assets/productos/2-congelado/12-zanahoria.webp"

import p_curcuma from "../Assets/productos/3-especies/1-curcuma.webp"
import p_achiote from "../Assets/productos/3-especies/2-achiote.webp"
import p_gengibre from "../Assets/productos/3-especies/3-gengibre.webp"
import p_hongos from "../Assets/productos/3-especies/4-hongos.webp"
import p_paprika from "../Assets/productos/3-especies/5-paprika.webp"
import p_oregano from "../Assets/productos/3-especies/6-oregano.webp"
import p_tomillo from "../Assets/productos/3-especies/7-tomillo.webp"

import p_mermelada from "../Assets/productos/4-mermelada/1-all.webp"

import p_lucuma from "../Assets/productos/5-superfood/1-lucuma.webp"
import p_kiwicha from "../Assets/productos/5-superfood/2-kiwicha.webp"
import p_maiz_morado from "../Assets/productos/5-superfood/3-maiz-morado.webp"
import p_quinuas from "../Assets/productos/5-superfood/4-quinuas.webp"
import p_chia from "../Assets/productos/5-superfood/5-chia.webp"

const text = {
    navbar: {
      nosotros: "About us",
      productos: "Products",
      cosechas: "Harvests",
      contacto: "Contact Us"
    },
    home: {
      titulo: "Professionals in Peruvian food exports",
      subtitulo: "We leverage our abundant biodiversity to fulfill your year-round demands",
      boton: "Contact Us"
    },
    about: {
      titulo: "About Us",
      subtitulo1: "CORVALE SAC comprises a team of professionals with extensive",
      subtitulo2: "expertise in Quality Control and Food Exports",
      tarjetas: [
        {
          titulo: "Compliance",
          subtitulo: "Collaborating with top-tier companies, renowned for professionalism and top-notch quality."
        },
        {
          titulo: "Social Commitment",
          subtitulo: "Environmentally-responsible production chains with a focus on native community collaboration."
        },
        {
          titulo: "Quality",
          subtitulo: "Companies committed to quality traceability."
        }
      ]
    },
    flavors: {
      titulo1: "Discover the",
      titulo2: "flavors of Perú",
      subtitulo: "Taste the difference with our products",
      tarjetas: [
        {
          nombre: "Preserves",
          jsonModal: [
            {
              titulo: "Preserves",
              fichas: [
                {imagen: p_esparragos,nombre: "Canned asparagus",boton: "See data sheet",datasheet: ds_esparragos},
                {imagen: p_gandules,nombre: "Canned pigeon peas",boton: "See data sheet",datasheet: ds_gandules},
                {imagen: p_puerro,nombre: "Canned leeks",boton: "See data sheet",        datasheet: ds_puerro}
              ]
            }
          ]
        },
        {
            nombre: "Frozen",
            jsonModal: [
              {
                titulo: "Frozen Fruits",
                fichas: [
                  {imagen: p_maracuya,nombre: "Passion fruit pulp",boton: "See data sheet",datasheet: ds_maracuya},
                  {imagen: p_mango,nombre: "Mango pulp",boton: "See data sheet",           datasheet: ds_mango},
                  {imagen: p_limon,nombre: "Lemmon juice",boton: "See data sheet",         datasheet: ds_limon}
                ]
              },
              {
                  titulo: "Frozen Vegetables",
                  fichas: [
                    {imagen: p_ajos,nombre: "Peeled garlics",boton: "See data sheet",                                datasheet: ds_ajos},
                  {imagen: p_camote,nombre: "Sweet potato",boton: "See data sheet",                                  datasheet: ds_camote},
                    {imagen: p_habas,nombre: "Peeled and frozen beans",boton: "See data sheet",                      datasheet: ds_habas},
                    {imagen: p_maiz,nombre: "Corn whole or chopped y pre-cooked and frozen",boton: "See data sheet", datasheet: ds_maiz},
                    {imagen: p_olluco,nombre: "Olluco chopped",boton: "See data sheet",                              datasheet: ds_olluco},
                    {imagen: p_vainita,nombre: "Pre-cooked and frozen chopped green beans",boton: "See data sheet",  datasheet: ds_vainita},
                    {imagen: p_yuca,nombre: "Chopped cassava",boton: "See data sheet",                               datasheet: ds_yuca},
                    {imagen: p_zapallo,nombre: "Chopped squash",boton: "See data sheet",                             datasheet: ds_zapallo},
                    {imagen: p_zanahoria,nombre: "Chopped sliced",boton: "See data sheet",                           datasheet: ds_zanahoria},
                  ]
                },
            ]
          },
          {
            nombre: "Espices",
            jsonModal: [
              {
                titulo: "Espices",
                fichas: [
                  {imagen: p_curcuma,nombre: "Turmeric powder",boton: "See data sheet",datasheet: ds_curcuma},
                  {imagen: p_achiote,nombre: "Annato powder",boton: "See data sheet",datasheet: ds_achiote},
                  {imagen: p_gengibre,nombre: "Dehydrated ginger in flakes",boton: "See data sheet",datasheet: ds_gengibre},
                  {imagen: p_hongos,nombre: "Dehydrated mushrooms with skin",boton: "See data sheet",datasheet: ds_hongos},
                  {imagen: p_paprika,nombre: "Paprika powder",boton: "See data sheet",datasheet: ds_paprika},
                  {imagen: p_oregano,nombre: "Drided oregano leaves",boton: "See data sheet",datasheet: ds_oregano},
                  {imagen: p_tomillo,nombre: "Thyme",boton: "See data sheet",datasheet: ds_tomillo},
                ]
              }
            ]
          },
          {
            nombre: "Jams",
            jsonModal: [
              {
                titulo: "Jams",
                fichas: [
                  {imagen: p_mermelada,nombre: "Tradicional jams",boton: "See data sheet",     datasheet: ds_tradicional},
                  {imagen: p_mermelada,nombre: "Confiture extra light",boton: "See data sheet",datasheet: ds_extralight},
                  {imagen: p_mermelada,nombre: "Data sheet diets jams",boton: "See data sheet",datasheet: ds_dietlight}
                ]
              }
            ]
          },
          {
            nombre: "Super Foods",
            jsonModal: [
              {
                titulo: "Super Foods",
                fichas: [
                  {imagen: p_lucuma,nombre: "Lucuma powder",boton: "See data sheet",   datasheet: ds_lucuma},
                  {imagen: p_kiwicha,nombre: "Kiwicha",boton: "See data sheet",        datasheet: ds_kiwicha},
                  {imagen: p_maiz_morado,nombre: "Purple corn",boton: "See data sheet",datasheet: ds_maizmorado},
                  {imagen: p_quinuas,nombre: "Quinoa",boton: "See data sheet",         datasheet: ds_quinuas},
                  {imagen: p_chia,nombre: "Chia seeds",boton: "See data sheet",        datasheet: ds_chia}
                ]
              }
            ]
          },
      ],
      boton: "See more"
    },
    cosechas: {
      titulo1: "We offer food all year",
      titulo2: "round",
      subtitulo: "Check our food harvest calendar",
      frutas: "Fruit harvest calendar",
      frutasJson: { titulo: "Fruit harvest calendar", imagen: "1" },
      vegetales: "Vegetable harvest calendar",
      vegetalesJson: { titulo: "Vegetable harvest calendar", imagen: "2" },
      boton: "See calendar"
    },
    contacto: {
      titulo1: "Discover additional options",
      titulo2: "Contact us",
      placeholder: [
        "Full Name",
        "Company",
        "Email",
        "+1",
        "Mobile Number",
        "Message"
      ],
      boton: "Send",
      direccion1: "54th Street No. 109; Office 204; Córpac Urbanization",
      direccion2: "San Isidro - Lima - Peru",
      gmail: "corvale8@gmail.com",
      celular: "+51 963 464 450",
      swalButton: "Accept",
      swalMessage: "Message sent successfully"
    },
    footer: {
      datos: ["About us", "Products", "Harvests", "Contact"]
    }
  };
  
  export default text;
