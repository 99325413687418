import "./App.css";
import React, { useState } from "react";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

import enText from './config/en'
import esText from './config/es'
function App() {
  const [idioma, setIdioma] = useState('es'); 
  let text;
  const cambiarImagen = () => {
    setIdioma(idioma === 'es' ? 'en' : 'es');
    text = (idioma==='es'?esText:enText)
  };
  
  text = (idioma==='es'?esText:enText)
  let sections = [
    {
      text: text.navbar.nosotros,
      icon: <HomeIcon />,
    },
    {
      text: text.navbar.productos,
      icon: <InfoIcon />,
    },
    {
      text: text.navbar.cosechas,
      icon: <CommentRoundedIcon />,
    },
    {
      text: text.navbar.contacto,
      icon: <PhoneRoundedIcon />,
    },
  ];

  const scrollToSection = (index) => {
    const element = document.getElementsByClassName('section')[index];
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <Home onLanguageChange={cambiarImagen} idioma={idioma} sections={sections} scrollToSection={scrollToSection} />
      <About onLanguageChange={cambiarImagen} idioma={idioma}/>
      <Work onLanguageChange={cambiarImagen} idioma={idioma}/>
      <Testimonial onLanguageChange={cambiarImagen} idioma={idioma}/>
      <Contact onLanguageChange={cambiarImagen} idioma={idioma}/>
      <Footer onLanguageChange={cambiarImagen} idioma={idioma} sections={sections} scrollToSection={scrollToSection}/>
    </div>
  );
}

export default App;