import React from 'react';
import './Card.css';

const Card = ({ imageSrc, subtitle, text }) => {
  return (
    <div className="card">
      <img src={imageSrc} alt="" className="card-image" />
      <h2 className="card-subtitle">{subtitle}</h2>
      <p className="card-text">{text}</p>
    </div>
  );
};

export default Card;