//Fichas técnicas
import ds_esparragos from "../Assets/fichas-tecnicas/1-conserva/1-esparragos-es.pdf"
import ds_gandules from "../Assets/fichas-tecnicas/1-conserva/2-gandules-es.pdf"
import ds_puerro from "../Assets/fichas-tecnicas/1-conserva/3-puerro-es.pdf"

import ds_maracuya from "../Assets/fichas-tecnicas/2-congelado/1-maracuya-es.pdf"
import ds_mango from "../Assets/fichas-tecnicas/2-congelado/2-mango-es.pdf"
import ds_limon from "../Assets/fichas-tecnicas/2-congelado/3-limon-es.pdf"
import ds_ajos from "../Assets/fichas-tecnicas/2-congelado/4-ajos-es.pdf"
import ds_camote from "../Assets/fichas-tecnicas/2-congelado/5-camote-es.pdf"
import ds_habas from "../Assets/fichas-tecnicas/2-congelado/6-habas-es.pdf"
import ds_maiz from "../Assets/fichas-tecnicas/2-congelado/7-maiz-es.pdf"
import ds_olluco from "../Assets/fichas-tecnicas/2-congelado/8-olluco-es.pdf"
import ds_vainita from "../Assets/fichas-tecnicas/2-congelado/9-vainita-es.pdf"
import ds_yuca from "../Assets/fichas-tecnicas/2-congelado/10-yuca-es.pdf"
import ds_zapallo from "../Assets/fichas-tecnicas/2-congelado/11-zapallo-es.pdf"
import ds_zanahoria from "../Assets/fichas-tecnicas/2-congelado/12-zanahoria-es.pdf"

import ds_curcuma from "../Assets/fichas-tecnicas/3-especies/1-curcuma-es.pdf"
import ds_achiote from "../Assets/fichas-tecnicas/3-especies/2-achiote-es.pdf"
import ds_gengibre from "../Assets/fichas-tecnicas/3-especies/3-gengibre-es.pdf"
import ds_hongos from "../Assets/fichas-tecnicas/3-especies/4-hongos-es.pdf"
import ds_paprika from "../Assets/fichas-tecnicas/3-especies/5-paprika-es.pdf"
import ds_oregano from "../Assets/fichas-tecnicas/3-especies/6-oregano-es.pdf"
import ds_tomillo from "../Assets/fichas-tecnicas/3-especies/7-tomillo-es.pdf"

import ds_tradicional from "../Assets/fichas-tecnicas/4-mermelada/1-tradicional-es.pdf"
import ds_extralight from "../Assets/fichas-tecnicas/4-mermelada/2-extralight-es.pdf"
import ds_dietlight from "../Assets/fichas-tecnicas/4-mermelada/3-dietlight-es.pdf"

import ds_lucuma from "../Assets/fichas-tecnicas/5-superfood/1-lucuma-es.pdf"
import ds_kiwicha from "../Assets/fichas-tecnicas/5-superfood/2-kiwicha-es.pdf"
import ds_maizmorado from "../Assets/fichas-tecnicas/5-superfood/3-maizmorado-es.pdf"
import ds_quinuas from "../Assets/fichas-tecnicas/5-superfood/4-quinuas-es.pdf"
import ds_chia from "../Assets/fichas-tecnicas/5-superfood/5-chia-es.pdf"

//Productos
import p_esparragos from "../Assets/productos/1-conserva/1-esparragos.webp"
import p_gandules from "../Assets/productos/1-conserva/2-gandules.webp"
import p_puerro from "../Assets/productos/1-conserva/3-puerro.webp"

import p_maracuya from "../Assets/productos/2-congelado/1-maracuya.webp"
import p_mango from "../Assets/productos/2-congelado/2-mango.webp"
import p_limon from "../Assets/productos/2-congelado/3-limon.webp"
import p_ajos from "../Assets/productos/2-congelado/4-ajos.webp"
import p_camote from "../Assets/productos/2-congelado/5-camote.webp"
import p_habas from "../Assets/productos/2-congelado/6-habas.webp"
import p_maiz from "../Assets/productos/2-congelado/7-maiz.webp"
import p_olluco from "../Assets/productos/2-congelado/8-olluco.webp"
import p_vainita from "../Assets/productos/2-congelado/9-vainita.webp"
import p_yuca from "../Assets/productos/2-congelado/10-yuca.webp"
import p_zapallo from "../Assets/productos/2-congelado/11-zapallo.webp"
import p_zanahoria from "../Assets/productos/2-congelado/12-zanahoria.webp"

import p_curcuma from "../Assets/productos/3-especies/1-curcuma.webp"
import p_achiote from "../Assets/productos/3-especies/2-achiote.webp"
import p_gengibre from "../Assets/productos/3-especies/3-gengibre.webp"
import p_hongos from "../Assets/productos/3-especies/4-hongos.webp"
import p_paprika from "../Assets/productos/3-especies/5-paprika.webp"
import p_oregano from "../Assets/productos/3-especies/6-oregano.webp"
import p_tomillo from "../Assets/productos/3-especies/7-tomillo.webp"

import p_mermelada from "../Assets/productos/4-mermelada/1-all.webp"

import p_lucuma from "../Assets/productos/5-superfood/1-lucuma.webp"
import p_kiwicha from "../Assets/productos/5-superfood/2-kiwicha.webp"
import p_maiz_morado from "../Assets/productos/5-superfood/3-maiz-morado.webp"
import p_quinuas from "../Assets/productos/5-superfood/4-quinuas.webp"
import p_chia from "../Assets/productos/5-superfood/5-chia.webp"

const text = {
    navbar: {
        nosotros: "Nosotros",
        productos: "Productos",
        cosechas: "Cosechas",
        contacto: "Contacto"
    },
    home: {
    titulo: "Profesionales en exportación de alimentos peruanos",
    subtitulo: "Aprovechamos nuestra rica biodiversidad para cumplir con sus demandas todo el año",
    boton: "Contáctenos"
    },
    about: {
    titulo: "Acerca de Nosotros",
    subtitulo1: "CORVALE SAC está formada por profesionales con experiencia",
    subtitulo2: "en Control de Calidad y Exportación de alimentos",
    tarjetas: [
        { titulo: "Cumplimiento", subtitulo: "Contamos con empresas líderes en su rubro, que destacan por su profesionalismo y calidad" },
        { titulo: "Compromiso Social", subtitulo: "Respeto por el Medio Ambiente y priorización de cadenas productivas con comunidades nativas" },
        { titulo: "Calidad", subtitulo: "Empresas que tienen pasión por la calidad y la trazabilidad" }
    ]
    },
    flavors: {
        titulo1: "Descubre los",
        titulo2: "sabores del Perú",
        subtitulo: "Prueba la diferencia con nuestros productos",
        tarjetas: [
            {nombre: "Conservas", jsonModal: [
                {
                  titulo: "Frutas Congeladas",
                  fichas: [
                      {imagen: p_esparragos,nombre: "Espárragos en conserva",boton: "Ver ficha técnica",datasheet: ds_esparragos},
                      {imagen: p_gandules,nombre: "Gándules en conserva",boton: "Ver ficha técnica",datasheet: ds_gandules},
                      {imagen: p_puerro,nombre: "Puerro en conserva",boton: "Ver ficha técnica",datasheet: ds_puerro}
                  ]
                }]},
            {nombre: "Congelados", jsonModal: [
                {
                    titulo: "Frutas Congeladas",
                    fichas: [
                        {imagen: p_maracuya,nombre: "Pulpa de maracuyá",boton: "Ver ficha técnica",datasheet: ds_maracuya},
                        {imagen: p_mango,nombre: "Pulpa de mango",boton: "Ver ficha técnica",datasheet: ds_mango},
                        {imagen: p_limon,nombre: "Zumo de limón",boton: "Ver ficha técnica",datasheet: ds_limon}
                    ]
                  },
                  {
                    titulo: "Vegetales Congelados",
                    fichas: [
                        {imagen: p_ajos,nombre: "Ajos pelados",boton: "Ver ficha técnica",                                  datasheet: ds_ajos},
                        {imagen: p_camote,nombre: "Camote pelado precocido",boton: "Ver ficha técnica",                     datasheet: ds_camote},
                        {imagen: p_habas,nombre: "Habas peladas precocidas",boton: "Ver ficha técnica",                     datasheet: ds_habas},
                        {imagen: p_maiz,nombre: "Maíz choclo precocido en granos y precocido",boton: "Ver ficha técnica",   datasheet: ds_maiz},
                        {imagen: p_olluco,nombre: "Ollucos en slices",boton: "Ver ficha técnica",                           datasheet: ds_olluco},
                        {imagen: p_vainita,nombre: "Vainita precocida y trozada",boton: "Ver ficha técnica",                datasheet: ds_vainita},
                        {imagen: p_yuca,nombre: "Yuca trozada",boton: "Ver ficha técnica",                                  datasheet: ds_yuca},
                        {imagen: p_zapallo,nombre: "Zapallo en cubos",boton: "Ver ficha técnica",                           datasheet: ds_zapallo},
                        {imagen: p_zanahoria,nombre: "Zanahoria en rodajas",boton: "Ver ficha técnica",                     datasheet: ds_zanahoria},
                    ]
                  },
              ]},
            {nombre: "Especies", jsonModal: [
                {
                    titulo: "Especies",
                    fichas: [
                        {imagen: p_curcuma,nombre: "Cúrcuma en polvo",boton: "Ver ficha técnica",datasheet: ds_curcuma},
                        {imagen: p_achiote,nombre: "Achiote en polvo",boton: "Ver ficha técnica",datasheet: ds_achiote},
                        {imagen: p_gengibre,nombre: "Jengibre deshidratado en ojuelas y en polvo",boton: "Ver ficha técnica",datasheet: ds_gengibre},
                        {imagen: p_hongos,nombre: "Hongos deshidratados con piel",boton: "Ver ficha técnica",datasheet: ds_hongos},
                        {imagen: p_paprika,nombre: "Páprika en polvo",boton: "Ver ficha técnica",datasheet: ds_paprika},
                        {imagen: p_oregano,nombre: "Orégano entero y molido",boton: "Ver ficha técnica",datasheet: ds_oregano},
                        {imagen: p_tomillo,nombre: "Tomillo",boton: "Ver ficha técnica",datasheet: ds_tomillo},
                    ]
                  },
              ]},
            {nombre: "Mermeladas", jsonModal: [
                {
                    titulo: "Mermeladas",
                    fichas: [
                        {imagen: p_mermelada,nombre: "Mermelada tradicional",boton: "Ver ficha técnica",datasheet: ds_tradicional},
                        {imagen: p_mermelada,nombre: "Mermelada extra light",boton: "Ver ficha técnica",datasheet: ds_extralight},
                        {imagen: p_mermelada,nombre: "Mermelada diet light",boton: "Ver ficha técnica",datasheet: ds_dietlight}
                    ]
                  },
              ]},
            {nombre: "Super Foods", jsonModal: [
                {
                    titulo: "Super Foods",
                    fichas: [
                        {imagen: p_lucuma,nombre: "Harina de lúcuma",boton: "Ver ficha técnica",datasheet: ds_lucuma},
                        {imagen: p_kiwicha,nombre: "Kiwicha",boton: "Ver ficha técnica",datasheet: ds_kiwicha},
                        {imagen: p_maiz_morado,nombre: "Maiz morado",boton: "Ver ficha técnica",datasheet: ds_maizmorado},
                        {imagen: p_quinuas,nombre: "Quinuas",boton: "Ver ficha técnica",datasheet: ds_quinuas},
                        {imagen: p_chia,nombre: "Semillas de chia",boton: "Ver ficha técnica",datasheet: ds_chia}
                    ]
                  },
              ]}],
        boton: "Ver más"
    },
    cosechas: {
        titulo1: "Ofrecemos alimentos",
        titulo2: "Todo el año",
        subtitulo: "Mira el calendario de cosecha de alimentos",
        frutas: "Calendario de cosecha de frutas",
        frutasJson: { titulo: "Calendario de cosecha de frutas", imagen: "1" },
        vegetales: "Calendario de cosecha de vegetales",
        vegetalesJson: { titulo: "Calendario de cosecha de vegetales", imagen: "2" },
        boton: "Ver más"
      },
      contacto: {
        titulo1: "¿Quieres saber más?",
        titulo2: "Contáctanos",
        placeholder: ["Nombre", "Compañía", "Email", "+51", "Teléfono", "Mensaje"],
        boton: "Enviar",
        direccion1: "Calle 54 N° 109; Of 204; Urb. Córpac",
        direccion2: "San Isidro - Lima - Perú",
        gmail: "corvale8@gmail.com",
        celular: "+51 963 464 450",
        swalButton: "Aceptar",
        swalMessage: "Se envió el mensaje correctamente"
      },
      footer: {
        datos: ["Nosotros", "Productos", "Cosechas", "Contacto"]
      }
  }


export default text;
