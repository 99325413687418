import React, { useState } from "react";
import TasteCard from './util/TasteCard';
import Congelados from "../Assets/work-congelados.png";
import Esparragos from "../Assets/work-esparragos.png";
import HarvestModal from './util/HarvestModal'
import cosechaFrutasImage from '../Assets/cosecha-frutas.png';
import cosechaFrutasEnImage from '../Assets/cosecha-frutas-en.png';
import cosechaVegImage from '../Assets/cosecha-veg.png';
import cosechaVegEnImage from '../Assets/cosecha-veg-en.png';

import enText from '../config/en'
import esText from '../config/es'

const Testimonial = ({ onLanguageChange, idioma }) => {

  const [showModal, setShowModal] = useState(false);
  const [jsonModal, setJsonModal] = useState();

  const openModal = (jsonModal) => {
    setShowModal(true);
    setJsonModal(jsonModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  let text = (idioma==='es'?esText:enText)  
  return (
    <div className="section">
      {showModal && (
        <HarvestModal
          title={jsonModal.titulo}
          image={idioma==='es'?( jsonModal.imagen === "1"?cosechaFrutasImage:cosechaVegImage):( jsonModal.imagen === "1"?cosechaFrutasEnImage:cosechaVegEnImage ) }
          onClose={closeModal}
          clase="harvest"
        />
      )}
      <div className="testimonial-background-title">
          <h2>{text.cosechas.titulo1}<br/>{text.cosechas.titulo2}</h2>
      </div>
      <div className="about-background-black-image-container">
          <h3>{text.cosechas.subtitulo}</h3>
      </div>
      <div className="work-background-image-container">

        <div className="testimonial-card-container-group">
          <div className="testimonial-card-container">
            <TasteCard
              imageSrc={Congelados}
              subtitle=  {text.cosechas.frutas} 
              button={text.cosechas.boton}
              onCardButtonClick={() => openModal(text.cosechas.frutasJson)}
            />
          </div>
          <div className="testimonial-card-container">
            <TasteCard
              imageSrc={Esparragos}
              subtitle={text.cosechas.vegetales}
              button={text.cosechas.boton}
              onCardButtonClick={() => openModal(text.cosechas.vegetalesJson)}
            />
          </div>
        </div>
          
      </div>
    </div>
  );
};

export default Testimonial;
