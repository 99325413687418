import React from 'react';
import './TasteCard.css';

const TasteCard = ({ imageSrc, subtitle, button , onCardButtonClick}) => {
  return (
    <div className="card">
      <img src={imageSrc} alt="" className="card-image" />
      <h2 className="card-subtitle">{subtitle}</h2>
      <button className="card-button" onClick={onCardButtonClick}>{button}</button>
    </div>
  );
};

export default TasteCard;